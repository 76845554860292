import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from 'react-router-dom';
import App from './App';
import { Home } from './public_pages/home';
import { About } from './public_pages/about';
import { Faq } from './public_pages/faq';
import { Rentals } from './public_pages/rentals';
import { TBB } from './public_pages/tbb';
import { NewHome } from './public_pages/newhome';
const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<App />}>
			<Route path="" element={<NewHome />} />
			<Route path="rentals" element={<Rentals />} />
			<Route path="about" element={<About />} />
			<Route path="faq" element={<Faq/>}/>
			<Route path="tbb" element={<TBB />}/>
		</Route>
	)
);

export default router;
