const isPrice = (price) => {
	if (price){
		return( <h4>$ {price}</h4>)
	}
	else {
		return
	}
}
const isDes = (des) => {
	if (des) {
		return (<div className="card-body item-des">
			<h5>{des}</h5>
	</div>)
	}
	else {
		return
	}
}
export function ExtraCard(props) {
	
	return (
		<div className="card item-card-wrapper">
			<img className="card-img-top item-preview-pic" src={props.info.img} />
			<div className="card-body item-text-container">
				<h5 className="card-title item-name">{props.info.name}</h5>
			</div>
			<div className="card-body item-des">
			{isDes(props.info.des)}
			</div>
			<div className="card-body item-cost">
				
				{isPrice(props.info.price)}
			</div>
			
		</div>
	);
}
export function MachineCard(props) {
	
	return (
		<div className="card item-card-wrapper">
			<img className="card-img-top item-preview-pic" src={props.info.img} />
			<div className="card-body item-text-container">
				<h5 className="card-title item-name">{props.info.name}</h5>
			</div>
			<div className="card-body item-des">
				<h5>{props.info.des}</h5>
			</div>
			<div className="card-body item-cost">
				
				{isPrice(props.info.price)}
			</div>
			
		</div>
	);
}
