export const items = {
	machines: {
		hotdogGrillLarge: {
			name: <>Hot Dog Roller(s)<br></br>[Large]<br></br>(24 hot dogs)</>,
			des: 'Provide your own condiments, hot dogs, and buns.',
			img: require('../media/items/hotdog_grill.jpg'),
			price: '55.00'
		},
		hotdogGrillSmall: {
			name: <>Hot Dog Roller(s)<br></br>[Small]<br></br>(18 hot dogs)</>,
			des: 'Provide your own condiments, hot dogs, and buns.',
			img: require('../media/items/hotdog_grill.jpg'),
			price: '45.00'
		},
		hotdogSteam: {
			name: 'Hot Dog Steamer(s)',
			des: 'Provide your own condiments, hot dogs, and buns.',
			img: require('../media/items/hotdog_steamer.jpg'),
			price: '35.00'
		},
		popcornLarge: {
			name: <>Popcorn Machine(s)<br></br>[Large]<br></br>(25-30 servings)</>,
			des: 'Popcorn Servings and Popcorn Bags are provided',
			img: require('../media/items/popcorn_maker.jpg'),
			price: '65.00'
		},
		popcornSmall: {
			name: <>Popcorn Machine(s)<br></br>[Small]<br></br>(15-18  servings)</>,
			des: 'Popcorn Servings and Popcorn Bags are provided',
			img: require('../media/items/popcorn_maker.jpg'),
			price: '50.00'
		},
		snowconeTabletop: {
			name: 'Snowcone Machine (Tabletop)',
			des: 'Includes 24oz of syrup enough for roughly 20 snow cones with cone',
			img: require('../media/items/snowcone_maker.jpg'),
			price: '55.00'
		},
		snowconeStandup: {
			name: 'Snowcone Machine (Standup)',
			des: 'Includes 24oz of syrup enough for roughly 20 snow cones with cone',
			img: require('../media/items/snowcone_maker.jpg'),
			price: '65.00'
		},
		cottonCandyTabletop: {
			name: 'Cotton Candy Machine (Tabletop)',
			des: 'Specialty Cones, Cups, and Sugar are provided. Includes 32 oz of sugar for roughly 30 cotton candy',
			img: require('../media/items/cottoncandy_maker.jpg'),
			price: '55.00'
		},
		cottonCandyStandup: {
			name: 'Cotton Candy Machine (Standup)',
			des: 'Specialty Cones, Cups, and Sugar are provided. Includes 32 oz of sugar for roughly 30 cotton candy',
			img: require('../media/items/cottoncandy_maker.jpg'),
			price: '55.00'
		}
	},
	extras: {
		snowconeSupplies: {
			name: 'Snowcone Supplies',
			des: 'Syrup, cones and straws for additional 20 servings',
			img: require('../media/items/SnowconeSupplies.PNG'),
			price: '12.50'
		},
		cottonCandySupplies: {
			name: 'Cotton Candy Supplies',
			des: 'Floss sugar and cones for additional 30 servings',
			img: require('../media/items/cottonCandySupplies.jpg'),
			price: '12.50'
		},
		popcornPrePack: {
			name: 'Popcorn Pre-Pack',
			des: ' 8.0oz, 6-8 servings',
			img: require('../media/items/popcornPrePack.jpg'),
			price: '3.50'

		},
		popcornRefill: {
			name: 'Popcorn',
			des: ' 12.0oz, 9-12 servings',
			img: require('../media/items/popcornSupply.jpg'),
			price: '4.25'

		},
		generator: {
			name: 'Generator',
			des: 'Generator to run all rentals with 8-10 hours of gas with rental',
			img: require('../media/items/generator.jpg'),
			price: '45.00'
		},
		extensionCordLarge: {
			name: 'Extension Cord (100ft)',
			des: '',
			img: require('../media/items/extension.jpg'),
			price: '7.00'
		},
		extensionCordSmall: {
			name: 'Extension Cord (50ft)',
			des: '',
			img: require('../media/items/extension.jpg'),
			price: '5.00'
		},
		dispenser: {
			name: 'Drink Dispenser(s)',
			des: '3 Gallons',
			img: require('../media/items/dispenser.jpg'),
			price: '12.50'
		},
		tote: {
			name: 'Party Bucket(s)',
			des: '20 qt',
			img: require('../media/items/tote.png'),
			price: '5.00'
		},
		coolerSM: {
			name: 'Cooler(s) (Small)',
			des: '60qt on wheels',
			img: require('../media/items/cooler-sm.jpg'),
			price: '7.50'
		},
		coolerLG: {
			name: 'Cooler(s) (Large) ',
			des: '120qt',
			img: require('../media/items/cooler-lg.jpg'),
			price: '15.00'
		}
	}
	
};
