/**
 * @prettier
 */
export const About = () => {
	return (
		<div className="container-fluid about-container">
			<div className="row about-wrapper-row">
				<div className="col about-wrapper-col">
					<div className="row about-header">
						<div className="about-header-col">
							<h1>About-Us</h1>
						</div>
					</div>
					<div className="row about-body">
						<p>
							Acreage Party Rental is a family operated small business, local to
							Loxahatchee and the Greater West Palm Beach area. Veteran and
							First responder owned, we pledge to bring a fun and supplementary
							party service to help distinguish some of your most cherished
							memories and enrich the parties that will be talked about from
							those days forward. With commercial grade concessions equipment,
							we can elevate the feel of any get together and assure everyone
							can focus on just enjoying the festivities. Whether looking for a
							carnival atmosphere, a graduation extravaganza or just an
							especially noteworthy birthday party or sporting event - we’re
							here to make it just a little easier on being able to enjoy the
							party rather than worrying over hosting it.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
