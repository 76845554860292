/**
 * @prettier
 */

import undraw1 from '../public_assets/media/undraw-1.svg';
import undraw2 from '../public_assets/media/undraw-2.svg';
import undraw3 from '../public_assets/media/undraw3.svg';
export const NewHome = () => {
	return (
		<div className="container-fluid" id="newhome-container">
			<div className="row first-row">
				<div className="background">
					<div className="pink-bg-div"></div>
					<div className="blue-strip">
						<img
							src={require('../public_assets/media/popcorn-edited.png')}
							id="popcorn-machine"
						/>
					</div>
				</div>

				<div className="col-0 col-lg-6 main-left"></div>
				<div className="col-12 col-lg-6 main-right">
					<div className="intro-container">
						<img
							src={require('../public_assets/media/banner-3.png')}
							className="right-banner"
						/>
						<a href="/rentals">
							<button className="btn">Check Our Rentals!</button>
						</a>
					</div>
				</div>
			</div>
			<div className="row row-2">
				<div className="steps">
					<div className="section-title">
						<h5>How It Works</h5>
					</div>
					<div className="container col-xxl-8 px-4 py-5" id="goals-anchor">
						<div className="row flex-lg-row-reverse align-items-center justify-content-center g-5 py-5">
							<div className="col-10 col-sm-8 col-lg-6">
								<div>
									<img
										src={undraw3}
										className="d-block mx-lg-auto img-fluid "
										alt="Bootstrap Themes"
										width="700"
										height="500"
										loading="lazy"
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<h2>Look Through Our Options</h2>
								<p className="lead">
									We try to bring you as many options as we can to make your
									party as stress-free as possible.
								</p>
							</div>
						</div>
						<div className="row flex-lg-row align-items-center justify-content-center g-5 py-5">
							<div className="col-10 col-sm-8 col-lg-6">
								<div>
									<img
										src={undraw1}
										className="d-block mx-lg-auto img-fluid "
										alt="Bootstrap Themes"
										width="700"
										height="500"
										loading="lazy"
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<h2>Contact Us and Reserve Your Order</h2>
								<p className="lead">
									Whether through the phone or email, we will always be ready to
									assist you with your rentals.
								</p>
							</div>
						</div>
						<div className="row flex-lg-row-reverse align-items-center justify-content-center g-5 py-5">
							<div className="col-10 col-sm-8 col-lg-6">
								<div>
									<img
										src={undraw2}
										className="d-block mx-lg-auto img-fluid "
										alt="Bootstrap Themes"
										width="700"
										height="500"
										loading="lazy"
									/>
								</div>
							</div>
							<div className="col-lg-6">
								<h2>Have A Great Event Using Our Rentals!</h2>
								<p className="lead">
									Enjoy your celebration after an easy delivery and setup
									process.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
