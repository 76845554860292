/**
 * @prettier
 */
export const TBB = () => {
	return (
		<div className="container-fluid tbb">
			<div className="row tbb-row">
				<div className="tbb-0">
					<img src={require('../public_assets/media/logotext2 (1).jpg')} />
					<h3>
						<strong>
							Whoops! This page isn't quite ready yet, be sure to check back.
						</strong>
					</h3>
				</div>

				<div className="tbb-1">
					<h5>
						<strong>Call us:</strong> +1 561-203-5391
					</h5>
					<h5>
						<strong>Email us: </strong>
						customersupport@acreagepartyrentals.com
					</h5>
				</div>

				<div className="tbb-2">
					<img src={require('../public_assets/media/cone.webp')} />
					<h4>Website still under construction</h4>
				</div>
			</div>
		</div>
	);
};
