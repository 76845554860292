import { Outlet } from 'react-router-dom';
import { Navigation } from './public_components/navigation';
import { Footer } from './public_components/footer';
import './styling.scss';

function App() {
	return (
		<div className="App">
			<Navigation />
			<Outlet />
			<Footer />
		</div>
	);
}

export default App;
