/**
 * @prettier
 */
import { FiPhoneOutgoing } from 'react-icons/fi';

export const Footer = () => {
	return (
		<div className="container-fluid footer-footer">
			<div className="row funshine-footer">
				<div className="footer-container">
					<img
						src={require('../public_assets/media/name-logo.png')}
						className="name-logo"
					/>
					<div className="icons">
						<a>
							<img
								src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/facebook/facebook-original.svg"
								className="icon facebook"
							/>
						</a>

						<a>
							<img
								src={require('../public_assets/media/instagram.png')}
								className="icon insta"
							/>
						</a>

						<a href="tel:5612035391" className="icon call">
							<button type="button" className="btn">
								<FiPhoneOutgoing />
							</button>
						</a>
					</div>
					<div className="footer-contact-buttons"></div>
					<div className=" footer-links">
						<div className="footer-link">
							<a href="/">
								<p>Home</p>
							</a>
						</div>

						<div className="footer-link">
							<a href="/rentals">
								<p>Rentals</p>
							</a>
						</div>

						<div className="footer-link">
							<a
								href="https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=customersupport@acreagepartyrentals.com&hl=en"
								rel="noopener noreferrer"
								target="_blank"
							>
								<p>Email Us</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
