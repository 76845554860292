/**
 * @prettier
 */
import { items } from '../public_assets/data/items';
import { ExtraCard, MachineCard } from '../public_components/itemCard';
export const Rentals = () => {
	const itemObj = Object.values(items.machines);
	const extraObj = Object.values(items.extras);
	console.log(itemObj);
	return (
		<div className="container-fluid rental-page">
			<div className="row rental-row-big">
				<div className="rental-margin"></div>
				<div className="rental-center">
					<div className="row rental-header-row">
						<img
							src={require('../public_assets/media/logotext2 (1).png')}
							className="logo-rentals"
						/>
						<h1>Available To Rent</h1>
					</div>
					<hr></hr>
					<div className="row rental-body-row">
						<div className="col-12 vertical-rental-container">
							<div className="row machines">
								<div className="rental-header-text">
									<h2>Machines</h2> <br></br>
									<h5>
										Every machine rental is available for: <br></br>Small
										machines $50 Table Top<br></br> Big machines $65
										<br />
									</h5>
									<p>
										{' '}
										<br></br>
										<strong>
											Renting two machines at once will change the cost to $120
										</strong>
										<br></br>
										<br></br>
										Minimum $75 rental fee.
										<br></br>All fees are per event
									</p>
									<h5 style={{ fontSize: '1rem' }}>Ask about bundle pricing</h5>
								</div>

								<div className="rental-container">
									{itemObj.map((item, index) => {
										return (
											<div className="itemcard-wrapper" key={index}>
												<MachineCard info={item} />{' '}
											</div>
										);
									})}
								</div>
							</div>
							<hr></hr>
							<div className="row extras">
								<div className="rental-header-text">
									<h2>Extras</h2>
									<h5>
										Extras are available to be added to your rentals as you'd
										like
									</h5>
									<p>All fees are per day</p>
								</div>
								<div className="extras-container">
									{extraObj.map((item, index) => {
										return (
											<div className="itemcard-wrapper" key={index}>
												<ExtraCard info={item} />{' '}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					<div className="row rental-disclaimers-row">
						<p>
							Includes everything in description. Additional portions available
							for purchase.
						</p>
						<p>
							Delivery is free within a 10 mile radius of 7050 Seminole Pratt
							Whitney Rd, Loxahatchee, FL 33470. <br></br>Add 1.50 per mile for
							deliveries outside of this area. <br></br>Gratuity optional
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
