/**
 * @prettier
 */
export const Faq = () => {
	return (
		<div className="container-fluid faq-container">
			<div className="row faq-wrapper-row">
				<div className="col faq-wrapper-col">
					<div className="row faq-header">
						<div className="faq-header-col"><h1>Frequently Asked Questions</h1></div>
					</div>
					<div className="row faq-body">
						<p>
							{/* <p className="question"></p><p className="answer"></p> */}
							<p className="question">
								Q: What kind of party supplies does your rental company offer?
							</p>

							<p className="answer">
								A: We offer a variety of party supplies including popcorn
								machines, cotton candy machines, hot dog rollers, coolers, and
								much more.
							</p>

							<p className="question">
								Q: Do you provide delivery and setup services?
							</p>

							<p className="answer">
								A: Yes, we provide delivery and setup services for all of our
								party rentals in Loxahatchee, FL. Delivery fees may apply
								depending on the location.
							</p>

							<p className="question">
								Q: How long can we rent the party supplies for?
							</p>
							<p className="answer">
								A: Our rental periods typically range from 1 day to multiple
								days depending on your needs. Please contact us for specific
								rental duration options.
							</p>
							<p className="question">Q: Are the party supplies easy to use?</p>

							<p className="answer">
								A: Yes, our party supplies are easy to use and come with
								instructions to help you set up and operate them. We also
								provide support if you have any questions or concerns during
								your rental period.
							</p>

							<p className="question">Q: Can we rent multiple items at once?</p>
							<p className="answer">
								A: Yes, you can rent multiple items at once to create the
								ultimate party experience. We offer package deals to help you
								save money on your rental order.
							</p>
							<p className="question">
								Q: What happens if the equipment malfunctions during our rental
								period?
							</p>
							<p className="answer">
								A: We perform regular maintenance on all of our equipment to
								ensure they are in proper working order. However, in the event
								that something malfunctions during your rental period, please
								contact us immediately so we can provide assistance and/or
								replace the equipment if necessary.
							</p>
							<p className="question">
								Q: Do you provide supplies such as popcorn kernels, cotton candy
								sugar, and hot dog buns?
							</p>

							<p className="answer">
								A: Yes, we offer additional supplies such as popcorn kernels,
								cotton candy sugar, and other party accessories. Please let us
								know what you need and we will make sure to include them in your
								rental package.
							</p>
							<p className="question">Q: How do we book a rental?</p>
							<p className="answer">
								A: You can book a rental by calling us at (phone number) or by
								visiting our website and filling out our online booking form. We
								recommend booking in advance to ensure availability.
							</p>

							<p className="question">
								Q: Are there any additional fees or deposits required to rent
								from your company?
							</p>

							<p className="answer">
								A: Yes, we may require a deposit for certain rentals, which will
								be refunded upon return of the equipment in good condition.
								Additionally, there may be delivery fees depending on the
								distance of your location from our facility.
							</p>
							<p className="question">
								Q: Can we pick up the rental equipment ourselves instead of
								having it delivered?
							</p>
							<p className="answer">
								A: Yes, you can arrange to pick up the rental equipment from our
								facility in Loxahatchee, FL. Please let us know in advance so we
								can ensure that everything is ready for you.
							</p>
							<p className="question">Q: What is your cancellation policy?</p>
							<p className="answer">
								A: We have a flexible cancellation policy and will work with you
								to reschedule or cancel your rental order if necessary. Please
								contact us as soon as possible if you need to make changes to
								your rental order.
							</p>
							<p className="question">
								Q: Can you accommodate large events or parties?
							</p>

							<p className="answer">
								A: Yes, we can accommodate parties and events of all sizes.
								Please contact us in advance to ensure availability and to
								discuss your specific needs.
							</p>
							<p className="question">
								Q: Do you offer discounts for multiple rentals or long-term
								rentals?
							</p>
							<p className="answer">
								A: Yes, we offer discounts for multiple rentals or long-term
								rentals. Please contact us for more information on our pricing
								and discounts.
							</p>
							<p className="question">
								Q: What kind of maintenance and cleaning do you perform on your
								rental equipment?
							</p>
							<p className="answer">
								A: We perform regular maintenance and cleaning on all of our
								rental equipment to ensure they are in good condition and ready
								for your use. This includes sanitizing and cleaning after each
								rental.
							</p>
							<p className="question">
								Q: Can you provide recommendations on which party supplies would
								be best for our event?
							</p>
							<p className="answer">
								A: Yes, we are happy to provide recommendations and help you
								choose the right party supplies for your event. Just let us know
								what kind of event you are planning and we can make suggestions
								based on our experience.
							</p>
							<p className="question">
								Q: What forms of payment do you accept?
							</p>
							<p className="answer">
								A: We accept cash and credit card payments for all of our
								rentals. Payment is due upon delivery or pickup of the rental
								equipment.
							</p>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
