/**
 * @prettier
 */
export const Navigation = () => {
	return (
		<nav className="navbar sticky-top navbar-expand-md funshine-nav navbar-light py-2">
			<a className="navbar-brand" href="/">
				<img
					src={require('../public_assets/media/gator-nobgnoborder.png')}
					className="logo-nav"
				/>
				<h1 className="funshine-nav-title">Acreage Party Rentals</h1>
			</a>
			<button
				className="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarNavbar"
				aria-controls="navbarBar"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse" id="navbarNavbar">
				<ul className="navbar-nav   ">
					<li className="nav-item active">
						<a className="nav-link" aria-current="page" href="/">
							Home
						</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/rentals">
							Rentals
						</a>
					</li>

					<li className="nav-item">
						<a className="nav-link" href="/about">
							About Us
						</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/faq">
							FAQ
						</a>
					</li>

					<li className="nav-item">
						<a
							href="https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=customersupport@acreagepartyrentals.com&hl=en"
							rel="noopener noreferrer"
							target="_blank"
						>
							<button className="btn contact-button">
								<span>Schedule Now</span>
							</button>
						</a>
					</li>
				</ul>
			</div>
		</nav>
	);
};
